'use client'

import { FunctionComponent, useContext } from 'react'
import useCurrentLocale from '../../../core/hook/useCurrentLocale'
import { ConfigContext, useTranslation } from '../../context/ConfigContext'
import { getStoresByShippingZone } from '../../../domain/store'

type ConfiguratorType = {
  displayShipping?: boolean
  className?: string
}

export const Configurator: FunctionComponent<ConfiguratorType> = ({
  className,
  displayShipping,
}) => {
  const currentLocale = useCurrentLocale()
  const { shippingZone, changeStoreCode, changeShippingZone, shippingZones } =
    useContext(ConfigContext)
  const t = useTranslation()

  const stores = getStoresByShippingZone(shippingZones, shippingZone ?? '') ?? []

  return (
    <ul className={`footer-selects ${className}`}>
      {displayShipping && (
        <li className='footer-select'>
          <label htmlFor='dispatching'>{t('footer-select-website-label', {}, true)}</label>
          <select
            className={`width ${shippingZone}`}
            id='dispatching'
            name='dispatching'
            defaultValue={shippingZone}
            onChange={(e) => {
              changeShippingZone(e.target.value)
            }}>
            {shippingZones?.map((zone) => (
              <option value={zone?.code ?? ''} key={zone?.code}>
                {zone?.name}
              </option>
            ))}
          </select>
        </li>
      )}
      <li className='footer-select'>
        <label htmlFor='store'>{t('footer-select-storeview-label', {}, true)}</label>
        <select
          className={`width ${currentLocale}`}
          id='store'
          name='store'
          defaultValue={stores.find((store) => store.code === currentLocale)?.domain ?? undefined}
          onChange={(e) => {
            changeStoreCode(e.target.value)
          }}>
          {stores.map((store) => {
            if (store && store.isActive === 1) {
              return (
                <option value={store.domain ?? ''} key={store.code}>
                  {store.name}
                </option>
              )
            }
          })}
        </select>
      </li>
    </ul>
  )
}
