'use client'

import { useEffect } from 'react'

const DEFAULT_SPEED = 0.2
const DEFAULT_OFFSET = 200

const imageParallaxSelector = '[data-speed]'

// copied from legacy (just translate old jQuery code to vanilla js)
const parallaxImageOnScrollListener = () => {
  const windowYOffset = window.pageYOffset
  const winHeight = window.innerHeight
  const winBottom = windowYOffset + winHeight

  const docHeight = Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.clientHeight,
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight,
  )

  document.querySelectorAll<HTMLElement>(imageParallaxSelector).forEach((el) => {
    const thisTop = el.getBoundingClientRect().top + window.scrollY
    if (thisTop - DEFAULT_OFFSET > winBottom) {
      return false
    }

    const speed = !isNaN(Number(el.dataset.speed))
      ? Number(el.dataset.speed) * -1
      : DEFAULT_SPEED * -1

    let val
    if (el.classList.contains('scroll-start-zero')) {
      val = windowYOffset * speed
    } else {
      val = (windowYOffset - thisTop + winHeight / 2) * speed
    }
    if (val > docHeight) {
      val = docHeight
    }
    const styleVal = 'translate3d( 0px, ' + val + 'px, 0px)'
    el.style.transform = styleVal
  })
}

const CmsBlockInitImagesParallax = () => {
  useEffect(() => {
    window.addEventListener('load', parallaxImageOnScrollListener)
    window.addEventListener('scroll', parallaxImageOnScrollListener)

    return () => {
      window.removeEventListener('load', parallaxImageOnScrollListener)
      window.removeEventListener('scroll', parallaxImageOnScrollListener)
    }
  }, [])

  return null
}

export default CmsBlockInitImagesParallax
