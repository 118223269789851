import { ReactNode, useState } from 'react'
import HeaderNavigationMobileLink from './HeaderNavigationMobileLink'

type HeaderNavigationMobileLevelProps = {
  levelTitle: string
  levelHref: string
  children: ReactNode
}

const HeaderNavigationMobileLevel = ({
  levelTitle,
  levelHref,
  children,
}: HeaderNavigationMobileLevelProps) => {
  const [isActive, setIsActive] = useState(false)

  return (
    <li className='header-navigation-mobile-level'>
      <HeaderNavigationMobileLink
        title={levelTitle}
        href={levelHref}
        onClick={() => setIsActive(true)}
      />
      <div className={`header-navigation-mobile-level-submenu ${isActive ? 'active' : ''}`}>
        <HeaderNavigationMobileLink
          title={levelTitle}
          href={levelHref}
          onClick={() => setIsActive(false)}
          isBacktrack
          bold
        />
        {children}
      </div>
    </li>
  )
}

export default HeaderNavigationMobileLevel
