export const headerMock = {
  data: {
    menu: {
      items: [
        {
          item_id: 1,
          title: 'Joaillerie',
          url: null,
          cms_page_identifier: null,
          parent_id: 0,
          category_id: 7,
          position: 10,
          megamenu_content:
            '<div class="menu-part menu-part-3">\r\n{{widget type="Ultranoir\\Menumanager\\Block\\Widget\\Category\\Images" default_category="108" category_ids="106,108,12,170,35,34,36,18,21,11,102,84,13,57,147,10,37,107,179"}}\r\n</div>\r\n<div class="menu-part menu-part-4">\r\n{{widget type="Ultranoir\\Menumanager\\Block\\Widget\\CategoryList" title="Par Catégories" category_ids="15,17,22, 23, 33, 74"}}\r\n{{widget type="Ultranoir\\Menumanager\\Block\\Widget\\CategoryList" title="Les Iconiques" category_ids="163,164,165,166"}}\r\n{{widget type="Ultranoir\\Menumanager\\Block\\Widget\\CategoryList" title="Idées Cadeaux" category_ids="144,75,168,68,148,76,39"}}\r\n</div>\r\n<div class="menu-part menu-part-3">\r\n{{widget type="Ultranoir\\Menumanager\\Block\\Widget\\CategoryList" title="Par Collections" link_to="7" category_ids="106,108,12,170,35,34,36,18,21,11,102,84,13,57,147,10,37,107,179"}}\r\n</div>',
          item_class: null,
          category: {
            image: '/media/catalog/tmp/category/messika-landing-joaillerie.jpg',
            id: 7,
            url_key: 'joaillerie',
            name: 'Joaillerie',
            thumbnail_image: null,
            mobile_thumbnail_image: null,
          },
          default_category: {
            image: '/media/catalog/tmp/category/messika-collection-move-uno-slider.jpg',
            id: 108,
            url_key: 'bijoux-move-uno',
            name: 'Move Uno',
            thumbnail_image:
              '/media/catalog/category/messika-collection-move-uno-slider-mobile.jpeg',
            mobile_thumbnail_image: null,
          },
          is_megamenu_active: true,
          submenu: {
            block_images: null,
            block_categories: [
              {
                title: 'Par Catégories',
                images_hover: false,
                collections_link_label: '',
                column_position: 1,
                categories: [
                  {
                    uid: '15',
                    url_key: 'bague-diamant',
                    name: 'Bagues',
                    url_path: 'joaillerie/categories/bague-diamant',
                    image: '/media/catalog/category/messika-noel-landing-banniere-desktop-1_1.jpg',
                    thumbnail_image: null,
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '17',
                    url_key: 'bracelet-diamant',
                    name: 'Bracelets',
                    url_path: 'joaillerie/categories/bracelet-diamant',
                    image: '/media/catalog/category/messika-home-winter-22-slider.jpg',
                    thumbnail_image: null,
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '22',
                    url_key: 'collier-diamant',
                    name: 'Colliers',
                    url_path: 'joaillerie/categories/collier-diamant',
                    image: '/media/catalog/category/messika-category-winter-22.jpg',
                    thumbnail_image: null,
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '23',
                    url_key: 'boucles-oreilles-diamant',
                    name: "Boucles d'oreilles",
                    url_path: 'joaillerie/categories/boucles-oreilles-diamant',
                    image:
                      '/media/catalog/category/messika-selection-noel-2022-slider-desktop-2_1.jpg',
                    thumbnail_image: null,
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '33',
                    url_key: 'boutons-de-manchettes',
                    name: 'Boutons de Manchettes',
                    url_path: 'joaillerie/categories/boutons-de-manchettes',
                    image: null,
                    thumbnail_image: null,
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '74',
                    url_key: 'cadeaux-luxe',
                    name: 'Accessoires',
                    url_path: 'joaillerie/categories/cadeaux-luxe',
                    image:
                      '/media/catalog/tmp/category/messika-paris-joaillerie-accessoires-slider-2.jpg',
                    thumbnail_image: null,
                    mobile_thumbnail_image: null,
                  },
                ],
              },
              {
                title: 'Les Iconiques',
                images_hover: false,
                collections_link_label: '',
                column_position: 1,
                categories: [
                  {
                    uid: '163',
                    url_key: 'selection-bagues-diamant-move',
                    name: 'Bagues Move',
                    url_path: 'jewelry/new/selection-bagues-diamant-move',
                    image: '/media/catalog/tmp/category/messika-category-bagues-move.jpg',
                    thumbnail_image: null,
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '164',
                    url_key: 'selection-bracelets-diamant-move',
                    name: 'Bracelets Move',
                    url_path: 'jewelry/new/selection-bracelets-diamant-move',
                    image: '/media/catalog/tmp/category/messika-category-bracelets-move.jpg',
                    thumbnail_image: null,
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '165',
                    url_key: 'selection-colliers-diamant-move',
                    name: 'Colliers Move',
                    url_path: 'jewelry/new/selection-colliers-diamant-move',
                    image: '/media/catalog/tmp/category/messika-category-colliers-move.jpg',
                    thumbnail_image: null,
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '166',
                    url_key: 'selection-boucles-oreilles-diamant-move',
                    name: "Boucles d'oreilles Move",
                    url_path: 'jewelry/new/selection-boucles-oreilles-diamant-move',
                    image: '/media/catalog/tmp/category/messika-category-boucles-oreilles-move.jpg',
                    thumbnail_image: null,
                    mobile_thumbnail_image: null,
                  },
                ],
              },
              {
                title: 'Idées Cadeaux',
                images_hover: false,
                collections_link_label: '',
                column_position: 2,
                categories: [
                  {
                    uid: '75',
                    url_key: 'selection-bijoux-move',
                    name: 'Iconiques Move ',
                    url_path: 'joaillerie/cadeaux/selection-bijoux-move',
                    image: '/media/catalog/tmp/category/messika-iconique-move-slider-desktop.jpg',
                    thumbnail_image: null,
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '168',
                    url_key: 'nouveautes-bijoux-diamant',
                    name: 'Nouveautés',
                    url_path: 'jewelry/cadeaux/nouveautes-bijoux-diamant',
                    image: '/media/catalog/tmp/category/messika-nouveautes-slider-eve-desktop.jpg',
                    thumbnail_image: null,
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '68',
                    url_key: 'petites-attentions-messika',
                    name: 'Moins de 1 500€',
                    url_path: 'joaillerie/cadeaux/petites-attentions-messika',
                    image:
                      '/media/catalog/tmp/category/messika-selection-petites-attentions-slider_1.jpg',
                    thumbnail_image: null,
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '148',
                    url_key: 'stacking-bracelets',
                    name: 'Stacking Bracelets',
                    url_path: 'jewelry/cadeaux/stacking-bracelets',
                    image:
                      '/media/catalog/tmp/category/messika-selection-stacking-bracelets-slider.jpg',
                    thumbnail_image: null,
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '76',
                    url_key: 'bar-a-bangles',
                    name: 'Bar à Bangles ',
                    url_path: 'joaillerie/cadeaux/bar-a-bangles',
                    image: '/media/catalog/tmp/category/messika-collection-move-noa-slider_1.jpg',
                    thumbnail_image: null,
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '39',
                    url_key: 'bijoux-homme',
                    name: 'Pour Homme',
                    url_path: 'joaillerie/cadeaux/bijoux-homme',
                    image:
                      '/media/catalog/tmp/category/messika-collection-move-titanium-slider_1.jpg',
                    thumbnail_image: null,
                    mobile_thumbnail_image: null,
                  },
                ],
              },
              {
                title: 'Par Collections',
                images_hover: true,
                collections_link_label: 'Parcourir les collections',
                column_position: 3,
                categories: [
                  {
                    uid: '106',
                    url_key: 'bijoux-lucky-move',
                    name: 'Lucky Move',
                    url_path: 'joaillerie/collections/bijoux-lucky-move',
                    image:
                      '/media/catalog/tmp/category/messika-kendall-collection-lucky-move-desktop.jpg',
                    thumbnail_image:
                      '/media/catalog/category/messika-collection-lucky-move-slider-mobile_1.jpeg',
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '108',
                    url_key: 'bijoux-move-uno',
                    name: 'Move Uno',
                    url_path: 'joaillerie/collections/bijoux-move-uno',
                    image: '/media/catalog/tmp/category/messika-collection-move-uno-slider.jpg',
                    thumbnail_image:
                      '/media/catalog/category/messika-collection-move-uno-slider-mobile.jpeg',
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '12',
                    url_key: 'bijoux-move-classique',
                    name: 'Move Classique',
                    url_path: 'joaillerie/collections/bijoux-move-classique',
                    image:
                      '/media/catalog/tmp/category/messika-collection-move-classique-slider-3.jpg',
                    thumbnail_image:
                      '/media/catalog/category/messika-collection-move-classique-slider-mobile_2.jpeg',
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '170',
                    url_key: 'bijoux-my-move',
                    name: 'My Move',
                    url_path: 'joaillerie/collections/bijoux-my-move',
                    image: '/media/catalog/tmp/category/messika-my-move-collection-slider.jpg',
                    thumbnail_image:
                      '/media/catalog/category/messika-my-move-collection-slider-mobile.jpeg',
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '35',
                    url_key: 'bijoux-move-noa',
                    name: 'Move Noa',
                    url_path: 'joaillerie/collections/bijoux-move-noa',
                    image: '/media/catalog/tmp/category/messika-collection-move-noa-slider.jpg',
                    thumbnail_image:
                      '/media/catalog/category/messika-collection-move-noa-slider-mobile_1.jpeg',
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '34',
                    url_key: 'bijoux-move-romane',
                    name: 'Move Romane',
                    url_path: 'joaillerie/collections/bijoux-move-romane',
                    image:
                      '/media/catalog/tmp/category/messika-kendall-collection-move-romane-desktop.jpg',
                    thumbnail_image:
                      '/media/catalog/category/messika-collection-move-romane-slider-mobile_1.jpeg',
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '36',
                    url_key: 'bijoux-move-10th',
                    name: 'Move 10th',
                    url_path: 'joaillerie/collections/bijoux-move-10th',
                    image: '/media/catalog/tmp/category/messika-collection-move-10th-slider.jpg',
                    thumbnail_image:
                      '/media/catalog/category/messika-collection-move-10th-slider-mobile-2.jpeg',
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '18',
                    url_key: 'bijoux-move-joaillerie',
                    name: 'Move Joaillerie',
                    url_path: 'joaillerie/collections/bijoux-move-joaillerie',
                    image: '/media/catalog/category/messika-collection-move-joaillerie_3.jpg',
                    thumbnail_image:
                      '/media/catalog/category/messika-collection-move-joaillerie-slider-mobile-2.jpeg',
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '21',
                    url_key: 'bijoux-move-titanium',
                    name: 'Move Titanium',
                    url_path: 'joaillerie/collections/bijoux-move-titanium',
                    image: '/media/catalog/category/messika-collection-move-titanium_2.jpg',
                    thumbnail_image:
                      '/media/catalog/category/messika-collection-move-titanium-slider-mobile.jpeg',
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '11',
                    url_key: 'bijoux-gatsby',
                    name: 'Gatsby',
                    url_path: 'jewelry/collections/gatsby',
                    image: '/media/catalog/tmp/category/collection-gatsby-slider.jpg',
                    thumbnail_image:
                      '/media/catalog/category/messika-collection-gatsby-slider-mobile.jpeg',
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '102',
                    url_key: 'bijoux-lucky-eye',
                    name: 'Lucky Eye',
                    url_path: 'joaillerie/collections/bijoux-lucky-eye',
                    image: '/media/catalog/tmp/category/messika-collection-lucky-eye-slider.jpg',
                    thumbnail_image:
                      '/media/catalog/category/messika-collection-lucky-eye-slider-mobile.jpeg',
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '84',
                    url_key: 'bijoux-my-first-diamond',
                    name: 'My First Diamond',
                    url_path: 'joaillerie/collections/bijoux-my-first-diamond',
                    image: '/media/catalog/category/messika-paris-collection-my-first---.jpg',
                    thumbnail_image:
                      '/media/catalog/category/messika-collection-my-first-slider-mobile-2_1.jpeg',
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '13',
                    url_key: 'bijoux-my-twin',
                    name: 'My Twin',
                    url_path: 'joaillerie/collections/bijoux-my-twin',
                    image: '/media/catalog/category/messika-paris-collection-my-twin-2.jpg',
                    thumbnail_image:
                      '/media/catalog/category/messika-collection-my-twin-slider-mobile-2.jpeg',
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '57',
                    url_key: 'bijoux-joy',
                    name: 'Joy',
                    url_path: 'joaillerie/collections/bijoux-joy',
                    image: '/media/catalog/category/messika-collection-joy_1.jpg',
                    thumbnail_image:
                      '/media/catalog/category/messika-collection-joy-slider-mobile-2.jpeg',
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '147',
                    url_key: 'bijoux-joy-coeur',
                    name: 'Joy Coeur',
                    url_path: 'joaillerie/collections/bijoux-joy-coeur',
                    image: '/media/catalog/tmp/category/messika-collection-joy-coeur-slider.jpg',
                    thumbnail_image:
                      '/media/catalog/category/messika-collection-joy-coeur-slider-mobile.jpeg',
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '10',
                    url_key: 'bijoux-glamazone',
                    name: "Glam'Azone",
                    url_path: 'joaillerie/collections/bijoux-glamazone',
                    image: '/media/catalog/tmp/category/collection-glamazone-slider.jpg',
                    thumbnail_image:
                      '/media/catalog/category/messika-collection-glamazone-slider-mobile-2.jpeg',
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '37',
                    url_key: 'bijoux-skinny',
                    name: 'Skinny',
                    url_path: 'joaillerie/collections/bijoux-skinny',
                    image: null,
                    thumbnail_image:
                      '/media/catalog/category/messika-collection-skinny-slider-mobile.jpeg',
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '107',
                    url_key: 'bijoux-desert-bloom',
                    name: 'Desert Bloom',
                    url_path: 'joaillerie/collections/bijoux-desert-bloom',
                    image: '/media/catalog/tmp/category/messika-collection-desert-bloom-slider.jpg',
                    thumbnail_image:
                      '/media/catalog/category/messika-collection-desert-bloom-slider-mobile_1.jpeg',
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '179',
                    url_key: 'bijoux-d-vibes',
                    name: 'D-Vibes',
                    url_path: 'joaillerie/collections/bijoux-d-vibes',
                    image:
                      '/media/catalog/category/messika-collection-d-vibes-slider-desktop-1.jpg',
                    thumbnail_image:
                      '/media/catalog/category/messika-collection-d-vibes-slider-1.jpg',
                    mobile_thumbnail_image:
                      '/media/catalog/category/messika-collection-d-vibes-slider-mobile_1.jpg',
                  },
                ],
              },
            ],
          },
        },
        {
          item_id: 14,
          title: 'Mariage',
          url: null,
          cms_page_identifier: null,
          parent_id: 0,
          category_id: 85,
          position: 20,
          megamenu_content:
            '<div class="menu-part menu-part-3">\r\n{{widget type="Ultranoir\\Menumanager\\Block\\Widget\\Category\\Images" default_category="90"}}\r\n</div>\r\n<div class="menu-part menu-part-4">\r\n{{widget type="Ultranoir\\Menumanager\\Block\\Widget\\CategoryList" title="Par Collections"  link_to="85" category_ids="90,91,92,93,145,146"}}\r\n<div class="widget block block-category-childs">\r\n    <div class="categories-container">\r\n        <div class="mega-menu-title">Savoir-faire Messika</div>\r\n        <div class="categories-list">\r\n            <div class="only-mobile menu-back">Savoir-faire Messika</div>\r\n            <div class="categories-list-wrapper scroll-wrapper">\r\n                <span><a class="category-link" href="{{store direct_url="collection-mariage/le-savoir-faire-messika/expertise-diamantaire"}}">Expertise Diamantaire</a></span>\r\n                <span><a class="category-link" href="{{store direct_url="actualites/solitaire-diamant-sur-mesure-messika"}}">Solitaire sur mesure</a></span>\r\n                <span><a class="category-link" href="{{store direct_url="actualites/les-4c-messika"}}"> Les 4C</a></span>\r\n                <span><a class="category-link" href="{{store direct_url="actualites/les-4c-messika"}}"> Nos guides</a></span>\r\n\r\n                </span>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</div>\r\n</div>\r\n<div class="menu-part menu-part-3">\r\n{{widget type="Ultranoir\\Menumanager\\Block\\Widget\\CategoryList" title="Par Catégories" category_ids="95,96,97,98"}}\r\n{{widget type="Ultranoir\\Menumanager\\Block\\Widget\\CategoryList" title="Cadeaux" category_ids="100,101"}}\r\n</div>',
          item_class: null,
          category: {
            image: '/media/catalog/tmp/category/slider-home-mariage.jpg',
            id: 85,
            url_key: 'collection-mariage',
            name: 'Collection Mariage',
            thumbnail_image: null,
            mobile_thumbnail_image: null,
          },
          default_category: {
            image: '/media/catalog/category/slider-bagues-fiancailles-femmes-messika-desktop-1.jpg',
            id: 90,
            url_key: 'bague-de-fiancailles',
            name: 'Bagues de Fiançailles',
            thumbnail_image: '/media/catalog/category/messika-collection-bagues-fiancailles.jpg',
            mobile_thumbnail_image: null,
          },
          is_megamenu_active: true,
          submenu: {
            block_images: null,
            block_categories: [
              {
                title: 'Par Collections',
                images_hover: false,
                collections_link_label: 'Parcourir les collections',
                column_position: 1,
                categories: [
                  {
                    uid: '90',
                    url_key: 'bague-de-fiancailles',
                    name: 'Bagues de Fiançailles',
                    url_path: 'mariage/collections-mariage/bague-de-fiancailles',
                    image:
                      '/media/catalog/category/slider-bagues-fiancailles-femmes-messika-desktop-1.jpg',
                    thumbnail_image:
                      '/media/catalog/category/messika-collection-bagues-fiancailles.jpg',
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '91',
                    url_key: 'alliance-femme',
                    name: 'Alliances Femme',
                    url_path: 'mariage/collections-mariage/alliance-femme',
                    image: '/media/catalog/category/slider-alliances-femmes-messika-desktop.jpg',
                    thumbnail_image:
                      '/media/catalog/category/messika-collection-alliances-femmes.jpg',
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '92',
                    url_key: 'alliance-homme',
                    name: 'Alliances Homme',
                    url_path: 'mariage/collections-mariage/alliance-homme',
                    image: '/media/catalog/category/slider-alliances-hommes-messika-desktop.jpg',
                    thumbnail_image:
                      '/media/catalog/category/messika-collection-alliances-hommes.jpg',
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '93',
                    url_key: 'bijoux-de-mariage',
                    name: 'Bijoux de Mariage',
                    url_path: 'mariage/collections-mariage/bijoux-de-mariage',
                    image: '/media/catalog/category/slider-bijoux-de-mariages-messika-desktop.jpg',
                    thumbnail_image:
                      '/media/catalog/category/messika-collection-bijoux-mariage.jpg',
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '145',
                    url_key: 'solitaires-exception',
                    name: "Solitaires d'Exception",
                    url_path: 'mariage/collections-mariage/solitaires-exception',
                    image:
                      '/media/catalog/category/slider-solitaires-exceptionnels-messika-desktop.jpg',
                    thumbnail_image:
                      '/media/catalog/category/messika-collection-solitaires-exceptionnels.jpg',
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '146',
                    url_key: 'joy-coeur',
                    name: 'Joy Coeur ',
                    url_path: 'mariage/bridal-collections/joy-coeur',
                    image: '/media/catalog/category/Joy-coeur-messika-banniere.jpg',
                    thumbnail_image: null,
                    mobile_thumbnail_image: null,
                  },
                ],
              },
              {
                title: 'Par Catégories',
                images_hover: true,
                collections_link_label: '',
                column_position: 3,
                categories: [
                  {
                    uid: '95',
                    url_key: 'bague-mariage',
                    name: 'Bagues',
                    url_path: 'mariage/mariage-categories/bague-mariage',
                    image: '/media/catalog/category/messika-bridal-categorie-bagues.jpg',
                    thumbnail_image: null,
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '96',
                    url_key: 'bracelet-mariage',
                    name: 'Bracelets',
                    url_path: 'mariage/mariage-categories/bracelet-mariage',
                    image: '/media/catalog/category/messika-bridal-categorie-bracelets.jpg',
                    thumbnail_image: null,
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '97',
                    url_key: 'collier-mariage',
                    name: 'Colliers',
                    url_path: 'mariage/mariage-categories/collier-mariage',
                    image: '/media/catalog/category/messika-bridal-categorie-colliers.jpg',
                    thumbnail_image: null,
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '98',
                    url_key: 'boucles-oreilles-mariage',
                    name: "Boucles d'oreilles",
                    url_path: 'mariage/mariage-categories/boucles-oreilles-mariage',
                    image: '/media/catalog/category/messika-bridal-categorie-boucles-doreilles.jpg',
                    thumbnail_image: null,
                    mobile_thumbnail_image: null,
                  },
                ],
              },
              {
                title: 'Cadeaux',
                images_hover: false,
                collections_link_label: '',
                column_position: 3,
                categories: [
                  {
                    uid: '100',
                    url_key: 'bijoux-mariage-femme',
                    name: 'Pour Elle',
                    url_path: 'mariage/mariage-cadeaux/bijoux-mariage-femme',
                    image: '/media/catalog/tmp/category/slider-mariage-bague-fiancaille_1.jpg',
                    thumbnail_image: null,
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '101',
                    url_key: 'bijoux-mariage-homme',
                    name: 'Pour Lui',
                    url_path: 'mariage/mariage-cadeaux/bijoux-mariage-homme',
                    image: '/media/catalog/category/messika-bridal-cadeaux-pour-lui.jpg',
                    thumbnail_image: null,
                    mobile_thumbnail_image: null,
                  },
                ],
              },
              {
                title: 'Savoir-faire messika',
                images_hover: false,
                collections_link_label: '',
                column_position: 2,
                categories: [
                  {
                    uid: '175',
                    url_key: 'expertise-diamantaire',
                    name: 'Expertise Diamantaire',
                    url_path: 'collection-mariage/le-savoir-faire-messika/expertise-diamantaire',
                    image: '/media/catalog/tmp/category/messika-bridal-savoir-faire-slider.jpg',
                    thumbnail_image: null,
                    mobile_thumbnail_image: null,
                  },
                ],
              },
            ],
          },
        },
        {
          item_id: 3,
          title: 'Haute joaillerie',
          url: null,
          cms_page_identifier: null,
          parent_id: 0,
          category_id: 8,
          position: 30,
          megamenu_content:
            '<div class="menu-part menu-part-3">\r\n{{widget type="Ultranoir\\Menumanager\\Block\\Widget\\Category\\Images" default_category="151" category_ids="151,153,176,104,150"}}\r\n</div>\r\n<div class="menu-part menu-part-4 no-cols">\r\n{{widget type="Ultranoir\\Menumanager\\Block\\Widget\\CategoryList" title="Par Collections" link_to="8" category_ids="151,153,176,104,150"}}\r\n</div>\r\n<div class="menu-part menu-part-3"></div>',
          item_class: null,
          category: {
            image: '/media/catalog/tmp/category/test-landing-haute-joaillerie-slider-2_1.jpg',
            id: 8,
            url_key: 'haute-joaillerie',
            name: 'Haute-Joaillerie',
            thumbnail_image: null,
            mobile_thumbnail_image: null,
          },
          default_category: {
            image: '/media/catalog/tmp/category/messika-no-rules-slider.jpg',
            id: 151,
            url_key: 'messika-by-kate-moss',
            name: 'Messika by Kate Moss',
            thumbnail_image:
              '/media/catalog/tmp/category/header-menu-haute-joaillerie-lucky-move.jpg',
            mobile_thumbnail_image: null,
          },
          is_megamenu_active: true,
          submenu: {
            block_images: null,
            block_categories: [
              {
                title: 'Par Collections',
                images_hover: true,
                collections_link_label: 'Parcourir les collections',
                column_position: 1,
                categories: [
                  {
                    uid: '151',
                    url_key: 'messika-by-kate-moss',
                    name: 'Messika by Kate Moss',
                    url_path: 'haute-joaillerie/collections/no-rules',
                    image: '/media/catalog/tmp/category/messika-no-rules-slider.jpg',
                    thumbnail_image:
                      '/media/catalog/tmp/category/header-menu-haute-joaillerie-lucky-move.jpg',
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '153',
                    url_key: 'messika-by-kate-moss-bts',
                    name: "L'univers Messika by Kate Moss",
                    url_path: 'haute-joaillerie/collections/messika-by-kate-moss-bts',
                    image: '/media/catalog/tmp/category/messika-no-rules-slider-bts.jpg',
                    thumbnail_image: null,
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '176',
                    url_key: 'defile-haute-joaillerie-kate-moss',
                    name: 'Défilé Haute Joaillerie Kate Moss',
                    url_path: 'haute-joaillerie/collections/defile-haute-joaillerie-kate-moss',
                    image: '/media/catalog/tmp/category/kate-moss-fashion-show-slider_1.jpg',
                    thumbnail_image: null,
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '104',
                    url_key: 'born-to-be-wild',
                    name: 'Born to be Wild',
                    url_path: 'haute-joaillerie/collections/born-to-be-wild',
                    image: '/media/catalog/tmp/category/messika-born-to-be-wild-slider.jpg',
                    thumbnail_image:
                      '/media/catalog/tmp/category/test-header-menu-haute-joaillerie-born-to-be-wild_1.jpg',
                    mobile_thumbnail_image: null,
                  },
                  {
                    uid: '150',
                    url_key: 'once-upon-a-time',
                    name: 'Once Upon A Time',
                    url_path: 'haute-joaillerie/collections/once-upon-a-time',
                    image: '/media/catalog/tmp/category/messika-once-upon-a-time-slider-_1.jpg',
                    thumbnail_image: null,
                    mobile_thumbnail_image: null,
                  },
                ],
              },
            ],
          },
        },
        {
          item_id: 4,
          title: 'La Maison Messika',
          url: null,
          cms_page_identifier: 'la-maison-messika',
          parent_id: 0,
          category_id: null,
          position: 40,
          megamenu_content:
            '<div class="maison-megamenu-wrapper">\r\n<ul>\r\n<li class="only-mobile"><a class="category-link-all" href="{{store _direct="la-maison-messika"}}"> <span class="house-name"> <span class="house-name-text">Tout sur la Maison</span> </span> </a></li>\r\n<li><a href="{{store _direct="le-diamant-en-heritage"}}"> <img class="only-desktop" title="la-maison-messika-le-diamant-en-heritage" src="{{media url="wysiwyg/messika-diamant-menu.jpg"}}" alt="la-maison-messika-le-diamant-en-heritage"><span class="house-name"> <span class="house-name-text">Le diamant en heritage</span> </span> </a></li>\r\n<li><a href="{{store _direct="atelier-messika"}}"> <img class="only-desktop" title="la-maison-messika-latelier-messika" src="{{media url="wysiwyg/messika-atelier-menu.jpg"}}" alt="la-maison-messika-latelier-messika"><span class="house-name"> <span class="house-name-text">L\'atelier Messika</span> </span> </a></li>\r\n<li><a href="{{store _direct="savoir-faire"}}"> <img class="only-desktop" title="la-maison-messika-un-savoir-faire-exceptionnel" src="{{media url="wysiwyg/messika-savoir-faire-menu.jpg"}}" alt="la-maison-messika-un-savoir-faire-exceptionnel"> <span class="house-name"> <span class="house-name-text">Un savoir faire d\'exception</span> </span> </a></li>\r\n<li><a href="{{store _direct="eloge-de-la-feminite"}}"> <img class="only-desktop" title="la-maison-messika-eloge-de-la-feminite" src="{{media url="wysiwyg/la-maison-messika-la-feminite-.jpg"}}" alt="la-maison-messika-eloge-de-la-feminite"> <span class="house-name"> <span class="house-name-text">L\'éloge de la féminité</span> </span> </a></li>\r\n<li><a href="{{store _direct="la-vision-messika"}}"> <img class="only-desktop" title="la-maison-messika-la-vision-messika" src="{{media url="wysiwyg/messika-vision-menu.jpg"}}" alt="la-maison-messika-la-vision-messika"><span class="house-name"> <span class="house-name-text">La vision Messika</span> </span> </a></li>\r\n</ul>\r\n</div>',
          item_class: null,
          category: null,
          default_category: null,
          is_megamenu_active: true,
          submenu: {
            block_images: [
              {
                image_url: 'media/wysiwyg/messika-diamant-menu.jpg',
                image_text: 'Le diamant en heritage',
                link: 'le-diamant-en-heritage',
              },
              {
                image_url: 'media/wysiwyg/messika-atelier-menu.jpg',
                image_text: "L'atelier Messika",
                link: 'atelier-messika',
              },
              {
                image_url: 'media/wysiwyg/messika-savoir-faire-menu.jpg',
                image_text: "Un savoir faire d'exception",
                link: 'savoir-faire',
              },
              {
                image_url: 'media/wysiwyg/la-maison-messika-la-feminite-.jpg',
                image_text: "L'éloge de la féminité",
                link: 'eloge-de-la-feminite',
              },
              {
                image_url: 'media/wysiwyg/messika-vision-menu.jpg',
                image_text: 'La vision Messika',
                link: 'la-vision-messika',
              },
            ],
            block_categories: null,
          },
        },
        {
          item_id: 5,
          title: 'Actualités',
          url: 'actualites',
          cms_page_identifier: null,
          parent_id: 0,
          category_id: null,
          position: 50,
          megamenu_content:
            '<div class="actus-type-wrapper">\r\n<ul>\r\n<li class="only-mobile"> \r\n  <a class="category-link-all" href="{{store _direct="actualites"}}">\r\n    <span class="type-name"> \r\n     <span class="type-name-text">Parcourir les actualités</span> \r\n    </span>\r\n  </a>\r\n</li>\r\n<li><a href="{{store _direct="actualites/celebrites"}}"><img class="only-desktop" title="messika-celebrites" src="{{media url="wysiwyg/messika-paris-celebrites-2.jpg"}}" alt="messika-celebrites" /> <span class="type-name"> <span class="type-name-text">Célébrités</span> </span> </a></li>\r\n<li><a href="{{store _direct="actualites/evenements"}}"> <img class="only-desktop" title="messika-events" src="{{media url="wysiwyg/messika-events.jpg"}}" alt="messika-events" /><span class="type-name"> <span class="type-name-text">Événéments</span> </span> </a></li>\r\n<li><a href="{{store _direct="actualites/messikatv"}}"> <img class="only-desktop" title="messika-Messika TV" src="{{media url="wysiwyg/messika-paris-tv-3.jpg"}}" alt="messika-Messika TV" /><span class="type-name"> <span class="type-name-text">Messika TV</span> </span> </a></li>\r\n<li><a href="{{store _direct="actualites/thisism"}}"> <img class="only-desktop" title="messika-this is M" src="{{media url="wysiwyg/messika-this-is-m-menu.jpg"}}" alt="messika-this is M" /> <span class="type-name"> <span class="type-name-text">This is M</span> </span> </a></li>\r\n<li><a href="{{store _direct="podcast-messika"}}"> <img class="only-desktop" title="messika-podcast" src="{{media url="wysiwyg/messika-podcast-menu-def.jpg"}}" alt="messika-Podcast" /> <span class="type-name"> <span class="type-name-text">Podcast</span> </span> </a></li>\r\n</ul>\r\n</div>',
          item_class: null,
          category: null,
          default_category: null,
          is_megamenu_active: true,
          submenu: {
            block_images: [
              {
                image_url: 'media/wysiwyg/messika-paris-celebrites-2.jpg',
                image_text: 'Messika TV',
                link: 'actualites/celebrites',
              },
              {
                image_url: 'media/wysiwyg/messika-events.jpg',
                image_text: 'Événéments',
                link: 'actualites/evenements',
              },
              {
                image_url: 'media/wysiwyg/messika-paris-tv-3.jpg',
                image_text: 'Messika TV',
                link: 'actualites/messikatv',
              },
              {
                image_url: 'media/wysiwyg/messika-this-is-m-menu.jpg',
                image_text: 'This is M',
                link: 'actualites/thisism',
              },
              {
                image_url: 'media/wysiwyg/messika-podcast-menu-def.jpg',
                image_text: 'Podcast',
                link: 'podcast-messika',
              },
            ],
            block_categories: null,
          },
        },
        {
          item_id: 6,
          title: 'Nos boutiques',
          url: 'nos-boutiques',
          cms_page_identifier: null,
          parent_id: 0,
          category_id: null,
          position: 60,
          megamenu_content: null,
          item_class: null,
          category: null,
          default_category: null,
          is_megamenu_active: false,
          submenu: {
            block_images: null,
            block_categories: null,
          },
        },
        {
          item_id: 12,
          title: 'Mon compte',
          url: 'https://www.messika.com/fr/customer/account/',
          cms_page_identifier: null,
          parent_id: 0,
          category_id: null,
          position: 70,
          megamenu_content: null,
          item_class: 'only-mobile',
          category: null,
          default_category: null,
          is_megamenu_active: false,
          submenu: {
            block_images: null,
            block_categories: null,
          },
        },
      ],
    },
  },
}
