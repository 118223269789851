import { buildFullUrlMedia } from '../../../../core/hook/utils'
import {
  MenuBlockItem,
  MenuBlockItemBlockCategory,
  MenuBlockItemBlockImage,
  MenuBlockItemCategory,
} from '../../../../domain/menuBlock.types'
import HeaderNavigationDesktopPushImage, {
  pushimageCategoryClassName,
} from './HeaderNavigationDesktopPushImage'
import Image from '../../Image/Image'
import Link from '../../Link/Link'

const findPushimageCategoryElement = (category: MenuBlockItemCategory) =>
  document.querySelector(`.${pushimageCategoryClassName}[data-category="${category.url_key}"]`)
const showImageHover = (category: MenuBlockItemCategory) => {
  findPushimageCategoryElement(category)?.classList.add('active')
}
const hideImageHover = (category: MenuBlockItemCategory) => {
  findPushimageCategoryElement(category)?.classList.remove('active')
}

const HeaderNavigationDesktopCategory = ({ category }: { category: MenuBlockItemCategory }) => (
  <li
    key={category?.id}
    className='header-navigation-desktop-category'
    onMouseOver={() => showImageHover(category)}
    onFocus={() => showImageHover(category)}
    onMouseOut={() => hideImageHover(category)}
    onBlur={() => hideImageHover(category)}>
    <Link href={category.url_path || ''}>{category.name}</Link>
  </li>
)

const HeaderNavigationDesktopCmsPage = ({ cms_page }: { cms_page }) => (
  <li key={`cms-page-${cms_page?.title}`} className='header-navigation-desktop-category'>
    <Link href={cms_page?.url_key}>{cms_page?.menu_title ?? cms_page.title}</Link>
  </li>
)

type HeaderNavigationDesktopBlockCategoryProps = {
  blockCategory: MenuBlockItemBlockCategory
  hrefItem?: string
  endCategory?: MenuBlockItemCategory
}

const HeaderNavigationDesktopBlockCategory = ({
  blockCategory,
  endCategory,
}: HeaderNavigationDesktopBlockCategoryProps) => {
  const categories = blockCategory.categories?.filter(
    (category) => !!category,
  ) as MenuBlockItemCategory[]

  const cmsPages = blockCategory.cms_pages?.filter((cms_page) => !!cms_page)

  const showTwoColumns = categories?.length > 10

  return (
    <div
      className={`header-navigation-desktop-blockcategory ${showTwoColumns ? 'twocolumns' : ''}`}>
      <p className='header-navigation-desktop-blockcategory-title'>{blockCategory.title}</p>
      <ul className='header-navigation-desktop-blockcategory-items'>
        {categories?.map((category) => (
          <HeaderNavigationDesktopCategory category={category} key={`categ-${category.name}`} />
        ))}
        {cmsPages && (
          <>
            {cmsPages?.map((cms_page) => (
              <HeaderNavigationDesktopCmsPage
                cms_page={cms_page}
                key={`cms-page-${cms_page?.title}`}
              />
            ))}
          </>
        )}
        {endCategory && (
          <li>
            <Link
              href={
                blockCategory.collections_link_url
                  ? `/${blockCategory.collections_link_url}`
                  : `/${endCategory.url_path}`
              }
              className='header-navigation-desktop-blockcategory-seemore'>
              {blockCategory.collections_link_label}
            </Link>
          </li>
        )}
      </ul>
    </div>
  )
}

const getCategoriesWithImageHover = (blockCategories: MenuBlockItemBlockCategory[]) => {
  const categoriesWithImageHover: MenuBlockItemCategory[] = []

  blockCategories.forEach((blockCategory) => {
    if (!blockCategory.images_hover || !blockCategory.categories) {
      return
    }

    blockCategory.categories.forEach((category) => {
      if (category) {
        categoriesWithImageHover.push(category)
      }
    })
  })

  return categoriesWithImageHover
}

const HeaderNavigationDesktopBlockCategories = ({ item }: { item: MenuBlockItem }) => {
  const { submenu, default_category, category } = item
  const hrefItem = default_category?.url_path ?? item.url ?? undefined

  if (!submenu?.block_categories) {
    return null
  }

  const blockCategories = submenu.block_categories.filter(
    (bc) => !!bc,
  ) as MenuBlockItemBlockCategory[]

  const firstColumn = blockCategories.filter((c) => c?.column_position === 1)
  const secondColumn = blockCategories.filter((c) => c?.column_position === 2)
  const thirdColumn = blockCategories.filter((c) => c?.column_position === 3)

  const categoriesWithImageHover = getCategoriesWithImageHover(blockCategories)
  const defaultImg = default_category?.thumbnail_image ?? ''

  return (
    <div className='header-navigation-desktop-submenu header-navigation-desktop-submenu--push'>
      <div className='header-navigation-desktop-submenu-wrapper'>
        <div className='header-navigation-desktop-submenu-pushimage-wrapper'>
          {default_category && (
            <HeaderNavigationDesktopPushImage
              extraClassName={'default'}
              pushHref={hrefItem}
              pushSrc={defaultImg}
              pushLabel={default_category.name ?? undefined}
            />
          )}
          {categoriesWithImageHover.map((category) => {
            if (!category.url_key) {
              return
            }
            return (
              <HeaderNavigationDesktopPushImage
                key={`pushImage-${category.name}`}
                pushHref={category.url_path || item.url || undefined}
                pushSrc={category.thumbnail_image || defaultImg}
                pushLabel={category.name || undefined}
                dataCategoryId={category.url_key || item.url || undefined}
              />
            )
          })}
        </div>
        <div className='header-navigation-desktop-submenu--push-blockcategories col1'>
          {firstColumn.map((column) => (
            <HeaderNavigationDesktopBlockCategory
              blockCategory={column}
              key={`push-${column.title}`}
              hrefItem={hrefItem}
              endCategory={
                column.collections_link_label ? (category as MenuBlockItemCategory) : undefined
              }
            />
          ))}
        </div>
        <div className='header-navigation-desktop-submenu--push-blockcategories col2'>
          {secondColumn.map((column) => (
            <HeaderNavigationDesktopBlockCategory
              blockCategory={column}
              key={`push-${column.title}`}
              hrefItem={hrefItem}
              endCategory={
                column.collections_link_label ? (category as MenuBlockItemCategory) : undefined
              }
            />
          ))}
        </div>
        <div className='header-navigation-desktop-submenu--push-blockcategories col3'>
          {thirdColumn.map((column) => (
            <HeaderNavigationDesktopBlockCategory
              blockCategory={column}
              key={`push-${column.title}`}
              hrefItem={hrefItem}
              endCategory={
                column.collections_link_label ? (category as MenuBlockItemCategory) : undefined
              }
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const HeaderNavigationDesktopBlockImages = ({ item }: { item: MenuBlockItem }) => {
  const { submenu } = item
  if (!submenu?.block_images) {
    return null
  }

  const blockImages = submenu.block_images.filter(
    (blockImage) => !!blockImage,
  ) as MenuBlockItemBlockImage[]

  return (
    <div className='header-navigation-desktop-submenu header-navigation-desktop-submenu--nopush'>
      <ul className='header-navigation-desktop-submenu--nopush-list'>
        {blockImages.map((blockImage) => (
          <li
            key={`${blockImage.image_url}_${blockImage.image_url}`}
            className='header-navigation-desktop-submenu--nopush-item'>
            <Link href={blockImage.link || ''}>
              {blockImage.image_url && (
                <figure className='contrast'>
                  <Image
                    src={buildFullUrlMedia(blockImage.image_url)}
                    alt=''
                    width={260}
                    height={260}
                  />
                </figure>
              )}
              <span>{blockImage.image_text}</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

const HeaderNavigationDesktopItem = ({ item }: { item: MenuBlockItem }) => {
  const hrefItem = item.category?.url_path ?? item.url ?? undefined

  return (
    <li className={`header-navigation-desktop-item category-${item.category_id}`}>
      <Link href={hrefItem || ''} className='header-navigation-desktop-item-title'>
        {item.title}
      </Link>
      {item.submenu?.block_categories && <HeaderNavigationDesktopBlockCategories item={item} />}
      {item.submenu?.block_images && <HeaderNavigationDesktopBlockImages item={item} />}
    </li>
  )
}

const filterItemsForDesktop = (menuItems: MenuBlockItem[]) =>
  menuItems.filter(
    (menuItem) => menuItem.item_class !== 'only-mobile' && menuItem.item_class !== 'hidden',
  )

type HeaderNavigationDesktopProps = {
  menuItems: MenuBlockItem[]
}

const HeaderNavigationDesktop = ({ menuItems }: HeaderNavigationDesktopProps) => {
  const filteredItems = filterItemsForDesktop(menuItems)

  return (
    <ul className='header-navigation-desktop'>
      {filteredItems.map((item) => (
        <HeaderNavigationDesktopItem key={`navigation-desktop-item-${item.item_id}`} item={item} />
      ))}
    </ul>
  )
}

export default HeaderNavigationDesktop
