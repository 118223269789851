import { MouseEventHandler } from 'react'
import { IconArrow } from '../../SliderUtils/ArrowNavigation'
import Link from '../../Link/Link'

type HeaderNavigationMobileLinkProps = {
  title: string
  href: string
  onClick?: () => void
  isBacktrack?: boolean
  noArrow?: boolean
  bold?: boolean
}

const HeaderNavigationMobileLink = ({
  title,
  href,
  onClick,
  isBacktrack = false,
  noArrow = false,
  bold = false,
}: HeaderNavigationMobileLinkProps) => {
  const onClickLink: MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (onClick) {
      e.preventDefault()
      onClick()
    }
  }

  return (
    <Link
      href={href}
      onClick={onClickLink}
      className={`header-navigation-mobile-link ${noArrow ? 'no-arrow' : ''} ${
        bold ? 'bold' : ''
      }`}>
      <span className='header-navigation-mobile-link-title'>
        {isBacktrack && <IconArrow className='header-navigation-mobile-link-arrow previous' />}
        {title}
      </span>
      {!isBacktrack && <IconArrow className='header-navigation-mobile-link-arrow' />}
    </Link>
  )
}

export default HeaderNavigationMobileLink
